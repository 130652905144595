export const WORDS = [
  'crema',
  'sezon',
  'praji',
  'merge',
  'hobby',
  'regla',
  'punct',
  'ponod',
  'melod',
  'teanc',
  'pedel',
  'erede',
  'secol',
  'palma',
  'cofer',
  'volei',
  'catel',
  'priza',
  'sabie',
  'vasla',
  'print',
  'vedea',
  'orfan',
  'agita',
  'prund',
  'necaz',
  'clima',
  'minut',
  'rugby',
  'pieri',
  'curea',
  'sterp',
  'ceafa',
  'bully',
  'clovn',
  'manji',
  'zbeng',
  'motto',
  'copre',
  'album',
  'buggy',
  'naste',
  'covor',
  'purec',
  'iarna',
  'piata',
  'anima',
  'vital',
  'leleu',
  'fedeu',
  'minim',
  'combo',
  'stand',
  'judet',
  'tenis',
  'veste',
  'lapte',
  'situa',
  'flora',
  'menaj',
  'fluid',
  'genom',
  'fauri',
  'acces',
  'zulum',
  'imens',
  'bloca',
  'humor',
  'haina',
  'merit',
  'sosea',
  'sunca',
  'rapid',
  'dolly',
  'devia',
  'atare',
  'nevod',
  'minge',
  'ragaz',
  'ofili',
  'cerne',
  'ecler',
  'decor',
  'ovreu',
  'deloc',
  'vecin',
  'izvor',
  'coree',
  'pranz',
  'manta',
  'civil',
  'caiet',
  'pagan',
  'blond',
  'buget',
  'inger',
  'gunoi',
  'zgura',
  'toast',
  'acord',
  'metro',
  'spune',
  'potop',
  'ghici',
  'pojar',
  'apune',
  'manie',
  'dodoc',
  'neveu',
  'celom',
  'zarva',
  'sleit',
  'adora',
  'maret',
  'onest',
  'necum',
  'zefir',
  'vibra',
  'aduce',
  'nutri',
  'intim',
  'mohor',
  'albus',
  'cetos',
  'forma',
  'macaz',
  'minti',
  'aceea',
  'tigru',
  'dolar',
  'debil',
  'robot',
  'bonom',
  'voiaj',
  'casca',
  'peple',
  'opreg',
  'matur',
  'epava',
  'bacon',
  'menou',
  'rosti',
  'aboli',
  'activ',
  'sanie',
  'felon',
  'bolta',
  'bresa',
  'scapa',
  'carpa',
  'salta',
  'marfa',
  'farsa',
  'clasa',
  'buhur',
  'aliaj',
  'urlup',
  'rigid',
  'porob',
  'furor',
  'salva',
  'onora',
  'atent',
  'zvelt',
  'nepot',
  'sfera',
  'fapta',
  'nefer',
  'efort',
  'omida',
  'tacut',
  'evita',
  'ninge',
  'oofor',
  'sapca',
  'lider',
  'antic',
  'indoi',
  'butuc',
  'pengo',
  'strat',
  'atata',
  'glenc',
  'moara',
  'lupon',
  'scobi',
  'jurat',
  'bilet',
  'capot',
  'goden',
  'borod',
  'natal',
  'borna',
  'duble',
  'popul',
  'felie',
  'turla',
  'front',
  'runda',
  'foaie',
  'comod',
  'preta',
  'fonce',
  'agrea',
  'covru',
  'noroc',
  'tiran',
  'spirt',
  'nociv',
  'penar',
  'chulo',
  'vorba',
  'baiat',
  'avant',
  'sirop',
  'vraji',
  'haine',
  'senin',
  'lurex',
  'dativ',
  'coleg',
  'romeu',
  'idila',
  'laser',
  'velur',
  'grila',
  'staul',
  'cocor',
  'juglu',
  'fiica',
  'volum',
  'green',
  'ulduc',
  'cursa',
  'taina',
  'miere',
  'hrana',
  'jupui',
  'porno',
  'dzelo',
  'ostas',
  'spion',
  'fruct',
  'radio',
  'ovreg',
  'posta',
  'furca',
  'conta',
  'peren',
  'doica',
  'forja',
  'loure',
  'stire',
  'incie',
  'garda',
  'gripa',
  'junel',
  'penon',
  'balon',
  'coboc',
  'lacom',
  'guler',
  'retea',
  'scund',
  'polen',
  'citet',
  'vreme',
  'pompa',
  'gamba',
  'cello',
  'okume',
  'reper',
  'panou',
  'jeler',
  'facla',
  'molon',
  'oleum',
  'siret',
  'elice',
  'botez',
  'petic',
  'palat',
  'podis',
  'slava',
  'vagon',
  'vaduv',
  'pofta',
  'vechi',
  'leghe',
  'epoca',
  'odgon',
  'anexa',
  'gauri',
  'rodeo',
  'muget',
  'ficat',
  'roche',
  'lipsa',
  'latin',
  'releu',
  'urias',
  'durea',
  'negel',
  'afara',
  'cinic',
  'bufon',
  'acasa',
  'strop',
  'menur',
  'sarpe',
  'timid',
  'fugar',
  'patru',
  'litru',
  'zeghe',
  'invoi',
  'educa',
  'chiar',
  'sorbi',
  'tacea',
  'huron',
  'avion',
  'beton',
  'calca',
  'creol',
  'maxim',
  'sofer',
  'mulge',
  'audia',
  'moral',
  'pohod',
  'broda',
  'presa',
  'frica',
  'peron',
  'raion',
  'pirat',
  'umple',
  'nazui',
  'cocor',
  'tasni',
  'vifor',
  'nimic',
  'herul',
  'motor',
  'semet',
  'galop',
  'cruta',
  'tavan',
  'dator',
  'zorel',
  'cutie',
  'stare',
  'paria',
  'namol',
  'ostil',
  'ponei',
  'popor',
  'asana',
  'cersi',
  'paine',
  'pogor',
  'locel',
  'lance',
  'afuma',
  'catre',
  'conte',
  'punga',
  'norod',
  'serie',
  'sifon',
  'flota',
  'masiv',
  'drama',
  'prost',
  'poduc',
  'ratat',
  'copil',
  'tunel',
  'ratie',
  'boala',
  'spala',
  'funie',
  'banui',
  'salam',
  'langa',
  'paste',
  'milos',
  'codru',
  'sfert',
  'crunt',
  'bursa',
  'zeama',
  'pacla',
  'adanc',
  'marca',
  'larma',
  'huluz',
  'sarut',
  'gomej',
  'bland',
  'umbla',
  'sonet',
  'infia',
  'boboc',
  'neted',
  'venin',
  'unchi',
  'ecran',
  'deuce',
  'plisc',
  'cizma',
  'proza',
  'morar',
  'tabla',
  'graba',
  'medic',
  'cumva',
  'huzur',
  'curat',
  'neder',
  'trasa',
  'holba',
  'raton',
  'puhoi',
  'alina',
  'stang',
  'bizui',
  'lombe',
  'poala',
  'buzer',
  'lalea',
  'drapa',
  'freca',
  'catun',
  'suplu',
  'anunt',
  'pudra',
  'amurg',
  'juriu',
  'muhur',
  'cadou',
  'maica',
  'tabel',
  'nerod',
  'mugur',
  'coace',
  'ursuz',
  'clece',
  'etala',
  'treaz',
  'bober',
  'sonor',
  'plumb',
  'jubeu',
  'pongo',
  'deget',
  'chehe',
  'ponor',
  'sapte',
  'frana',
  'purta',
  'scrie',
  'sufla',
  'dulce',
  'adult',
  'sursa',
  'cafea',
  'scump',
  'banda',
  'capra',
  'visin',
  'mumie',
  'rugbi',
  'colon',
  'iunie',
  'modul',
  'tihna',
  'nunta',
  'momuc',
  'regur',
  'polua',
  'sfant',
  'helge',
  'bezer',
  'albie',
  'merge',
  'putea',
  'parau',
  'magar',
  'duker',
  'scuza',
  'foehn',
  'grajd',
  'celed',
  'fular',
  'mediu',
  'marti',
  'cules',
  'poate',
  'avans',
  'curaj',
  'buged',
  'preot',
  'pruna',
  'xerox',
  'dever',
  'chebe',
  'blana',
  'acolo',
  'stofa',
  'evada',
  'bagaj',
  'dalta',
  'aripa',
  'sovai',
  'intai',
  'moloz',
  'modem',
  'boaba',
  'comun',
  'crupe',
  'solid',
  'soare',
  'legal',
  'roade',
  'aduna',
  'sigur',
  'morun',
  'pogon',
  'codon',
  'dublu',
  'tarta',
  'joben',
  'asalt',
  'obroc',
  'subit',
  'circa',
  'greoi',
  'casti',
  'torta',
  'cresa',
  'ocara',
  'tarus',
  'gazda',
  'eroic',
  'difuz',
  'picta',
  'dauna',
  'guler',
  'arata',
  'hrani',
  'rigla',
  'maree',
  'joule',
  'numen',
  'varsa',
  'gaura',
  'spori',
  'ceche',
  'locui',
  'copac',
  'cauta',
  'zmeur',
  'rheno',
  'priva',
  'zecer',
  'inima',
  'pripa',
  'festa',
  'juger',
  'lenes',
  'geana',
  'nivel',
  'joaca',
  'belea',
  'terci',
  'spera',
  'voleu',
  'masca',
  'bojoc',
  'oleom',
  'iulie',
  'intre',
  'clonc',
  'jupon',
  'zavor',
  'scena',
  'greva',
  'vreun',
  'barba',
  'fanta',
  'curry',
  'henry',
  'cruce',
  'ierta',
  'apuca',
  'rozol',
  'divin',
  'varui',
  'defel',
  'medie',
  'oblon',
  'frige',
  'infam',
  'zbura',
  'kendo',
  'lampa',
  'jeleu',
  'vreme',
  'scurt',
  'dulap',
  'spuma',
  'umbre',
  'mania',
  'inalt',
  'coreu',
  'servi',
  'pleda',
  'jefui',
  'ibric',
  'culme',
  'cervu',
  'lesin',
  'vapor',
  'carte',
  'dosar',
  'roman',
  'limba',
  'blama',
  'stima',
  'gujon',
  'aluat',
  'banal',
  'zebra',
  'polje',
  'zuluf',
  'desen',
  'scara',
  'buton',
  'linie',
  'vaita',
  'folos',
  'fidel',
  'ulita',
  'mereu',
  'balet',
  'rupta',
  'bunic',
  'tigla',
  'odeon',
  'velum',
  'seism',
  'scula',
  'sonda',
  'nebun',
  'chelu’',
  'oblon',
  'bujie',
  'vrere',
  'volve',
  'reper',
  'coleg',
  'satul',
  'anume',
  'pence',
  'scuar',
  'cazma',
  'julep',
  'largi',
  'bubui',
  'dubiu',
  'crapa',
  'crede',
  'reusi',
  'colur',
  'creta',
  'curba',
  'cucon',
  'seara',
  'ruina',
  'umfla',
  'polon',
  'miros',
  'bufet',
  'caine',
  'liric',
  'surub',
  'aseza',
  'zacea',
  'cocon',
  'altar',
  'vatra',
  'moale',
  'pulsa',
  'coloz',
  'dolby',
  'loden',
  'cadea',
  'brusc',
  'calup',
  'mahni',
  'pauza',
  'certa',
  'organ',
  'cheie',
  'marai',
  'pista',
  'rival',
  'hotar',
  'talie',
  'viata',
  'barfi',
  'falca',
  'bucla',
  'turna',
  'soclu',
  'jilav',
  'arama',
  'carne',
  'gluga',
  'doliu',
  'vulpe',
  'baraj',
  'ideal',
  'repez',
  'asuma',
  'decis',
  'colun',
  'monta',
  'moder',
  'spita',
  'freon',
  'barca',
  'bivol',
  'lauda',
  'ciupi',
  'codor',
  'duios',
  'eocen',
  'echer',
  'oferi',
  'tipic',
  'odios',
  'penny',
  'ergol',
  'verva',
  'piele',
  'dinte',
  'ghips',
  'motai',
  'chist',
  'lucid',
  'genol',
  'stema',
  'obron',
  'topor',
  'deces',
  'harta',
  'cinci',
  'peste',
  'noneu',
  'holon',
  'fenec',
  'furou',
  'omite',
  'cucor',
  'intra',
  'podea',
  'haita',
  'roneo',
  'herme',
  'paroh',
  'reuni',
  'bronz',
  'obosi',
  'grota',
  'primi',
  'motiv',
  'rural',
  'plati',
  'vitel',
  'feder',
  'varza',
  'coaja',
  'bufni',
  'obraz',
  'bidon',
  'noroi',
  'forta',
  'minus',
  'leone',
  'penaj',
  'zobon',
  'ceapa',
  'ocupa',
  'fiere',
  'scrum',
  'campa',
  'voder',
  'topai',
  'chefe',
  'vecie',
  'cifra',
  'bojog',
  'clovn',
  'crump',
  'volum',
  'ceara',
  'pacat',
  'voloc',
  'eunuc',
  'grabi',
  'joben',
  'capat',
  'misca',
  'ospat',
  'pasta',
  'orice',
  'curge',
  'trada',
  'cubeb',
  'dejun',
  'cuhne',
  'comuz',
  'balta',
  'dereg',
  'luciu',
  'holum',
  'dusca',
  'zadar',
  'livra',
  'stins',
  'debut',
  'molie',
  'gluma',
  'goana',
  'carie',
  'fusta',
  'narav',
  'sclav',
  'birou',
  'macar',
  'socru',
  'pasti',
  'pleca',
  'chiul',
  'avere',
  'gorun',
  'teren',
  'piper',
  'credo',
  'fener',
  'bogat',
  'clama',
  'tanar',
  'lucra',
  'siroi',
  'cusur',
  'pisic',
  'xerom',
  'opera',
  'metal',
  'final',
  'duhni',
  'cobur',
  'leafa',
  'firma',
  'tinut',
  'remuu',
  'masea',
  'ultim',
  'muzeu',
  'tipar',
  'zburd',
  'dejun',
  'regal',
  'domol',
  'enorm',
  'lista',
  'rogoz',
  'ciuda',
  'serif',
  'loial',
  'ramas',
  'varia',
  'porni',
  'ponov',
  'bufeu',
  'fazan',
  'etapa',
  'canta',
  'fonem',
  'delta',
  'fleng',
  'virus',
  'cefeu',
  'ciclu',
  'bucal',
  'argou',
  'copii',
  'pluta',
  'homoc',
  'razes',
  'fuzee',
  'vesel',
  'peria',
  'orele',
  'sueta',
  'major',
  'sansa',
  'pasiv',
  'plumb',
  'serba',
  'devon',
  'conob',
  'bazin',
  'cecum',
  'geniu',
  'ferdu',
  'fudul',
  'opune',
  'copoi',
  'aloca',
  'chior',
  'volan',
  'posac',
  'munca',
  'ticsi',
  'bluza',
  'urban',
  'proba',
  'fonon',
  'forum',
  'mozol',
  'turma',
  'veghe',
  'pasaj',
  'durox',
  'munte',
  'magic',
  'enorm',
  'pisoi',
  'pilot',
  'amagi',
  'lucru',
  'imita',
  'culme',
  'lobby',
  'clipa',
  'edita',
  'foita',
  'suvoi',
  'secat',
  'lunar',
  'istet',
  'erupe',
  'nitui',
  'model',
  'liber',
  'atlas',
  'penel',
  'banca',
  'dineu',
  'liceu',
  'sapun',
  'narui',
  'garaj',
  'popou',
  'numai',
  'culca',
  'nylon',
  'crima',
  'ocean',
  'palid',
  'plaja',
  'plata',
  'grefa',
  'plivi',
  'sacai',
  'irosi',
  'aluna',
  'cupla',
  'capac',
  'sange',
  'mosie',
  'atlet',
  'toiag',
  'dupre',
  'acele',
  'fizic',
  'furie',
  'heneu',
  'fonta',
  'faima',
  'sport',
  'rebut',
  'cuget',
  'cromo',
  'negot',
  'atasa',
  'judec',
  'trupa',
  'acest',
  'privi',
  'sfida',
  'tinde',
  'bufon',
  'punge',
  'gecko',
  'varga',
  'preda',
  'gasca',
  'joker',
  'razui',
  'stalp',
  'coasa',
  'valid',
  'jumbo',
  'sopti',
  'dicta',
  'mereu',
  'hamal',
  'apasa',
  'boboc',
  'veghe',
  'trece',
  'plonj',
  'fumur',
  'autor',
  'steag',
  'tarif',
  'criza',
  'scoci',
  'emite',
  'boier',
  'parea',
  'decat',
  'pitic',
  'vraja',
  'dieta',
  'teama',
  'nopeu',
  'ceaun',
  'bizon',
  'relon',
  'catar',
  'pisca',
  'plasa',
  'etans',
  'impar',
  'ordin',
  'trist',
  'lansa',
  'nubuc',
  'camin',
  'izola',
  'voios',
  'nisip',
  'cablu',
  'acuza',
  'vesta',
  'titei',
  'piron',
  'meloe',
  'manca',
  'musca',
  'vlaga',
  'maner',
  'coboz',
  'linte',
  'uzina',
  'iarba',
  'creep',
  'placa',
  'admis',
  'incet',
  'lauta',
  'cadru',
  'febra',
  'abona',
  'amuza',
  'zeita',
  'irita',
  'chema',
  'trisa',
  'modru',
  'alege',
  'polei',
  'piesa',
  'valva',
  'trage',
  'odata',
  'bocce',
  'floem',
  'usura',
  'cupeu',
  'panza',
  'rebel',
  'tanji',
  'teaca',
  'mobil',
  'minte',
  'vinde',
  'agata',
  'adica',
  'crede',
  'peron',
  'sumar',
  'valet',
  'mezon',
  'sipet',
  'moroc',
  'ulcer',
  'cocos',
  'adaos',
  'mongo',
  'magie',
  'grunz',
  'trata',
  'venit',
  'boxer',
  'abtii',
  'tunde',
  'cuier',
  'recul',
  'cacao',
  'melon',
  'tarie',
  'lugol',
  'abate',
  'coama',
  'hotel',
  'sarma',
  'scuti',
  'teava',
  'ferma',
  'becer',
  'lupta',
  'maior',
  'exces',
  'firav',
  'perla',
  'coada',
  'tinta',
  'parte',
  'dejer',
  'comod',
  'clipi',
  'mugur',
  'curte',
  'coase',
  'verde',
  'gomon',
  'gaina',
  'bluff',
  'scalp',
  'turba',
  'manui',
  'sarac',
  'aroma',
  'pohor',
  'stiva',
  'moped',
  'cuplu',
  'actor',
  'codas',
  'plana',
  'ceata',
  'numar',
  'mesaj',
  'ponce',
  'relua',
  'ravna',
  'rabda',
  'racni',
  'taram',
  'rulou',
  'salut',
  'oglej',
  'amana',
  'negru',
  'pahar',
  'zahar',
  'papuc',
  'perce',
  'niste',
  'gelos',
  'dunga',
  'dobru',
  'drona',
  'arcus',
  'sorti',
  'pilda',
  'prada',
  'grava',
  'godeu',
  'cladi',
  'slabi',
  'scaun',
  'lumen',
  'creek',
  'boldo',
  'spate',
  'putin',
  'frate',
  'apara',
  'tatic',
  'briza',
  'faina',
  'galon',
  'cruce',
  'tapet',
  'hoher',
  'roata',
  'ciuma',
  'ajuta',
  'exact',
  'pombe',
  'fuzeu',
  'uncie',
  'burta',
  'refec',
  'lacat',
  'maine',
  'sever',
  'racai',
  'copia',
  'clomb',
  'dormi',
  'docil',
  'efect',
  'bronz',
  'unghi',
  'canoe',
  'oaste',
  'taran',
  'delco',
  'canal',
  'drept',
  'optim',
  'rebec',
  'cufar',
  'pomou',
  'truda',
  'pipai',
  'fatal',
  'jigni',
  'cauza',
  'zodie',
  'cumen',
  'matca',
  'aspru',
  'sedea',
  'panta',
  'minor',
  'grija',
  'neamt',
  'opune',
  'puber',
  'wolof',
  'anula',
  'nobil',
  'chila',
  'copie',
  'muzeu',
  'prund',
  'refuz',
  'logic',
  'pazea',
  'solar',
  'vicia',
  'titlu',
  'comic',
  'perna'
]