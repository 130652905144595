import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { WORDLE_TITLE } from '../constants/strings'

export const shareStatus = (guesses: string[], lost: boolean) => {
	var losewin = lost ? 'nu am gasit cuvantul :(' : 'am gasit cuvantul din '+guesses.length+' incercari'
	var txt = "Astazi "+losewin + " - https://wordle.ro.teodan.com/"
  	window.open(`https://api.whatsapp.com/send?text=`+txt)
}

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((letter, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩'
            case 'present':
              return '🟨'
            default:
              return '⬜'
          }
        })
        .join('')
    })
    .join('\n')
}
