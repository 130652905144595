export const WORDLE_TITLE = 'Wordle RO'

export const WIN_MESSAGES = ['Bravo!', 'Super!', 'Foarte bine!']
export const GAME_COPIED_MESSAGE = 'Jocul a fost copiat'
export const ABOUT_GAME_MESSAGE = 'Despre acest joc'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Litere insuficiente'
export const WORD_NOT_FOUND_MESSAGE = 'Cuvantul nu exista in dictionar'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Cuvantul corect era ${solution}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Sterge'
export const STATISTICS_TITLE = 'Statistici'
export const GUESS_DISTRIBUTION_TEXT = 'Parcurs'
export const NEW_WORD_TEXT = 'Un nou cuvant in'
export const SHARE_TEXT = 'Distribuie'
export const TOTAL_TRIES_TEXT = 'Total incercari'
export const SUCCESS_RATE_TEXT = 'Rata de succes'
export const CURRENT_STREAK_TEXT = 'Consecutive'
export const BEST_STREAK_TEXT = 'Record'
